<template>
    <component :is="currentComponent"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "Onboarding",
    computed: {
        status() {
            return this.$store.getters.user?.status
        },
        currentComponent() {
            switch (this.status) {
                case 'onboarding_candidate':
                    return 'CandidateOnboarding';
                case 'onboarding_client':
                    return 'ClientOnboarding';
                default:
                    return 'PickOnboarding';
            }
        },
    },
    created() {
        if (! this.status) {
            this.$router.push({ name: 'Home' })
        }
    },
    components: {
        CandidateOnboarding: defineAsyncComponent(() =>
            import("@/views/onboarding/CandidateOnboarding")
        ),
        ClientOnboarding: defineAsyncComponent(() =>
            import("@/views/onboarding/ClientOnboarding")
        ),
        PickOnboarding: defineAsyncComponent(() =>
            import("@/views/onboarding/PickOnboarding")
        ),
    }
}
</script>

<style scoped>

</style>